<script>
import { machineService } from "../../../helpers/backend/machine.service"
import { advertisementService } from "../../../helpers/backend/advertisement.service"
import { principleService } from "../../../helpers/backend/principle.service"
import AdvertisementTag from "./advertisement-tag.vue"
import maps from "../component/maps.vue"
export default {
  components: {
    AdvertisementTag,
    maps,
  },
  data() {
    return {
      form: {
        id: null,
        serial_number: "",
        password: "",
        name: "",
        location: "",
        machine_type_id: null,
        principle_id: null,
        outlet_id: null,
        advertisement_ids: [],
        coordinate: {
          lat: -6.191435,
          lng: 106.844124,
        },
      },
      advertisement_options: [
        { text: "Apple", id: 1 },
        { text: "Orange", id: 2 },
        { text: "Banana", id: 3 },
        { text: "Lime", id: 4 },
        { text: "Peach", id: 5 },
      ],
      busy: false,
      machine_type_options: [{ text: "Select One", value: null }],
      outlet_options: [{ text: "Select One", value: null }],
      principle_options: [{ text: "Select One", value: null }],
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
      renderComponent: true,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      machineService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null && data.machine != null) {
            advertisementService
              .assignAdvertisement(data.machine.id, this.form.advertisement_ids)
              .then(
                (adData) => {
                  if (adData != null) {
                    this.reset()
                    this.$emit("refresh")
                    this.showDismissibleAlert = true
                    this.error = ""
                    this.setActive(false)
                    this.busy = false
                  }
                },
                (adError) => {
                  this.showDismissibleAlert = true
                  this.error =
                    "Failed to Insert Machine Advertisement. Error : " +
                    adError
                  this.busy = false
                }
              )
          } else {
            this.showDismissibleAlert = true
            this.error =
              "Something wrong, machine id missing when trying to assign the Advertisement"
            this.busy = false
          }
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Insert/Update Machine. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.serial_number = ""
      this.form.password = ""
      this.form.name = ""
      this.form.location = ""
      this.form.machine_type_id = null
      this.form.principle_id = null
      this.form.outlet_id = null
      this.form.advertisement_ids = []
      this.form.coordinate = {
        lat: -6.191435,
        lng: 106.844124,
      }
      this.$refs.mapsRef.setPosition(
        this.form.coordinate.lat,
        this.form.coordinate.lng
      )
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    setPosition(position) {
      this.form.coordinate = position
    },
    initUpdate(machineId) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.serial_number.$el.focus()
        this.busy = true
        machineService.getMachineByID(machineId).then(
          (data) => {
            if (data.machine != null) {
              advertisementService
                .getAdvertisementsTypeByMachineId(data.machine.id)
                .then(
                  (adData) => {
                    this.form.id = data.machine.id
                    this.form.serial_number = data.machine.serial_number
                    this.form.name = data.machine.name
                    this.form.location = data.machine.location
                    this.form.machine_type_id = data.machine.machine_type_id
                    this.form.outlet_id = data.machine.outlet_id
                    this.form.principle_id = data.machine.principle_id.Int32
                    if (adData.advertisements) {
                      this.form.advertisement_ids = adData.advertisements.map(
                        (ad) => {
                          return ad.id
                        }
                      )
                      this.renderComponent = false

                      this.$nextTick(() => {
                        // Add the component back in
                        this.renderComponent = true
                      })
                    }
                    this.form.coordinate = data.machine.coordinate
                    this.$refs.mapsRef.setPosition(
                      this.form.coordinate.lat,
                      this.form.coordinate.lng
                    )
                    this.busy = false
                  },
                  (adErr) => {
                    this.busy = false
                    console.log(
                      "Unknown error when fetch advertisement data of machine. Error : " +
                      adErr
                    )
                  }
                )
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get machine to update. Error : Machine not found"
            }
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get machine to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchData() {
      machineService.getAllMachineType().then(
        (data) => {
          this.machine_type_options = this.machine_type_options.concat(
            data.machine_types.map(function (mType) {
              return { text: mType.name, value: mType.id }
            })
          )
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machine type. Error : " + err
        }
      )
      advertisementService.getAllAdvertisement().then((data) => {
        this.advertisement_options = data.advertisements.map(function (ad) {
          return { text: ad.name, id: ad.id }
        })
      })
      advertisementService.getAllOutlet().then((data) => {
        this.outlet_options = this.outlet_options.concat(
          data.outlets.map(function (outlet) {
            return { text: outlet.name, value: outlet.id }
          })
        )
      })
      principleService.getAll().then(
        (data) => {
          this.principle_options = this.principle_options.concat(
            data.principles.map(function (mType) {
              return { text: mType.name, value: mType.id }
            })
          )
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get product type. Error : " + err
        }
      )
      machineService.getLastMachineSerialNumber().then(
          (data) => {
            let newSerialNumber = ''
            let currentDate = (new Date().getFullYear() + "").substring(2, 4) + 
                ( (new Date().getMonth() + 1) < 10? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1) ) + "" + 
                ( (new Date().getDate()) < 10? "0"+new Date().getDate() : (new Date().getDate()) );
            if(this.form.serial_number == '' || this.form.serial_number == null){
              let lastSerialNumberId = data.machine.serial_number
              if(lastSerialNumberId.length == 10){
                // get last serial number
                lastSerialNumberId = lastSerialNumberId.substring(6, 10)
                lastSerialNumberId = parseInt(lastSerialNumberId)
                newSerialNumber = lastSerialNumberId+1
                while( (newSerialNumber+"").length < 4){
                  newSerialNumber = "0" + newSerialNumber
                }
                newSerialNumber = currentDate + newSerialNumber
              }else{
                newSerialNumber = currentDate + "0001"
              }

            }
            this.form.serial_number = newSerialNumber
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get serial . Error : " + err
          }
        )
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Serial Number:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.serial_number"
                  type="text"
                  placeholder="Enter Serial Number"
                  required
                  :minlength=4
                  ref="serial_number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Password:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  placeholder="Enter password"
                  type="password"
                  :required="form.id == null ? true : false"
                  :minlength=8
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-name"
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Enter Name"
                  required
                  :minlength=4
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Location:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.location"
                  placeholder="Enter Location"
                  required
                  :minlength=4
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label="Machine Type:"
                label-for="input-4"
              >
                <b-form-select
                  id="input-4"
                  v-model="form.machine_type_id"
                  :options="machine_type_options"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Outlet:"
                label-for="input-4"
              >
                <b-form-select
                  id="input-4"
                  v-model="form.outlet_id"
                  :options="outlet_options"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-5"
                label="Principle : "
                label-for="input-5"
              >
                <b-form-select
                  id="input-5"
                  v-model="form.principle_id"
                  :options="principle_options"
                ></b-form-select>
              </b-form-group>
              <maps
                :coordinate="this.form.coordinate"
                ref="mapsRef"
                @changePosition="setPosition"
              />
              <advertisement-tag
                v-model="form.advertisement_ids"
                :options="advertisement_options"
                v-if="renderComponent"
              />
              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div> -->
</template>



