<script>
import { machineService } from "../../../helpers/backend/machine.service"
import { productService } from "../../../helpers/backend/product.service"

const VarTemplate = {
  render() {
    return this.$scopedSlots.default(this.$attrs)
  },
}

export default {
  components: {
    VarTemplate,
  },
  data() {
    return {
      form: {
        planograms: [],
      },
      product_options: [{ text: "Select One", value: null }],
      products: [],
      planoRow: 0,
      planoColumn: 0,
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      console.log(this.form)

      machineService.updatePlanogramData(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Planogram. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      this.form.planograms = []
      this.products = []
      this.product_options = [{ text: "Select One", value: null }]
      this.planoRow = 0
      this.planoColumn = 0
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    getPlanoData(i, y) {
      return this.form.planograms[(i - 1) * this.planoColumn + (y - 1)]
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        machineService.getAllPlanogramsByMachineID(id).then(
          (data) => {
            if (data.planograms) {
              for (let index = 0;index < data.planograms.length;index++) {
                const p = data.planograms[index]
                this.planoRow = this.planoRow < p.position[0] ? p.position[0] : this.planoRow
                this.planoColumn = this.planoColumn < p.position[1] ? p.position[1] : this.planoColumn
                if (p.product_id == null) {
                  p.product = null
                }
              }
              this.form.planograms = data.planograms
              this.planoRow++
              this.planoColumn++
              this.busy = false
            }
          },
          (err) => {
            this.busy = false
            this.errMsg = err
          }
        )

        productService.getAllProduct().then(
          (data) => {
            if (data.products) {
              this.products = data.products
              this.product_options = data.products.map(function (product) {
                // return { text: product.name, name: product.name, value: product.id, data: product }
                return { text: product.name, value: product.id }
              })
              // this.product_options.unshift({ text: "Select One", value: null, name:"Select One", data: null })
              this.product_options.unshift({ text: "Select One", value: null })
            }
          },
          (err) => {
            this.busy = false
            this.errMsg = err
          }
        )


      })
    },
    formatRupiah(angka, prefix) {
      var number_string = angka
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        var separator = sisa ? "." : ""
        rupiah += separator + ribuan.join(".")
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : ""
    },
    changeProduct(data, planogram) {
      if (data) {
        planogram.product_id = data
        // this.$set(planogram, 'product', this.products[this.products.findIndex(p => p.id == data)])
        // console.log(planogram)
        planogram.product = this.products[this.products.findIndex(p => p.id == data)]
      } else {
        planogram.product_id = null
        planogram.product = null
      }

    }



    // invokeCreate() {
    //   this.setActive(true);
    // },

  },
};
</script>

<style scoped>
.planogram-group {
  margin-bottom: 24px;
}
.planogram-group > div {
  display: block;
  overflow-x: auto;
}
.planogram-group > div > .row {
  flex-wrap: nowrap;
  transform: translateX(15px);
}
.planogram-group > div > .row .product-item {
  display: inline-block;
  float: none;
  position: relative;
}

.product-item {
  min-width: 200px;
  min-height: 200px;
  border-radius: 25px;
  box-shadow: none;
  margin: 25px;
}

.card-img-left {
  transform: translateX(-10px);
  position: relative;
  z-index: 2;
  width: 70px;
  height: 200px;
  float: left;
}
.card-content {
  margin: 0;
  padding: 0;
}
.cursor {
  cursor: pointer;
}
</style>
<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay
              :show="busy"
              rounded="lg"
              opacity="0.6"
              class="card-content"
            >
              <div class="planogram-group">
                <div class="container-fluid">
                  <b-card-group
                    class="row"
                    v-for="i in planoRow"
                    :key="'plano-row-' + i"
                  >
                    <div v-for="y in planoColumn" :key="'temp-' + y">
                      <VarTemplate :planogram="getPlanoData(i, y)">
                        <b-card
                          slot-scope="{ planogram }"
                          :key="'plano-col-' + y"
                          :title="
                            !!planogram.product
                              ? planogram.product.sku
                              : 'Empty'
                          "
                          :sub-title="
                            '[' +
                            String.fromCharCode(65 + planogram.position[0]) +
                            (planogram.position[1] + 1) +
                            ']'
                          "
                          class="col-xs-4 product-item"
                          :img-src="
                            !!planogram.product
                              ? planogram.product.image_url
                              : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
                          "
                          img-left
                        >
                          <b-card-text>
                            {{
                              !!planogram.product
                                ? planogram.product.name
                                : "Empty"
                            }}
                          </b-card-text>

                          <!-- {{ planogram.product_id }} -->

                          <b-form-select
                              id="input-name"
                              v-model="planogram.product_id"
                              :options="product_options"
                              v-on:change="changeProduct($event, planogram)"
                              :disabled="planogram.quantity == 0 ? false : true"
                              
                            >
                          </b-form-select>
                          <!-- v-if="!!planogram.product_id" -->

                          <!-- <b-form-select
                              id="input-name"
                              v-else
                              v-model="planogram.product"
                              :options="product_options"
                              v-on:change="changeProduct($event, planogram)"
                            >
                          </b-form-select> -->

                          <!-- <b-card-text>
                            Quantity :
                            <b-form-input
                              id="input-quantity"
                              v-model.number="planogram.quantity"
                              placeholder="Quantity (pcs)"
                              type="number"
                            ></b-form-input>
                          </b-card-text>

                          <b-card-text>
                            Status :
                            <b-form-input
                              id="input-status"
                              v-model="planogram.status"
                              placeholder="Planogram Status"
                            ></b-form-input>
                          </b-card-text> -->
                        </b-card>
                      </VarTemplate>
                    </div>
                  </b-card-group>
                </div>
              </div>

              <b-button class="mr-2" type="submit" variant="primary">
                Update planograms
              </b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group> -->
  </div>
</template>



