var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.busy,"rounded":"lg","opacity":"0.6"}},[_c('div',{staticClass:"row"},[_c('b-alert',{attrs:{"variant":"error","fade":"","show":_vm.errMsg.length > 0}},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")])],1),_c('div',{staticClass:"container planogram-group"},[_c('div',{staticClass:"container-fluid"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-3 text-primary cursor",attrs:{"data-toggle":"tooltip","title":"Edit"},on:{"click":function($event){return _vm.updatePlanogram()}}},[_c('i',{staticClass:"mdi mdi-pencil font-size-18"}),_vm._v(" Edit Planogram ")]),_vm._l((_vm.planoRow),function(i){return _c('b-card-group',{key:'plano-row-' + i,staticClass:"row"},_vm._l((_vm.planoColumn),function(y){return _c('div',{key:'temp-' + y},[_c('VarTemplate',{attrs:{"planogram":_vm.getPlanoData(i, y)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var planogram = ref.planogram;
return _c('b-card',{key:'plano-col-' + y,staticClass:"col-xs-4 product-item",attrs:{"title":!!planogram.product ? planogram.product.sku : 'Empty',"sub-title":'[' +
                String.fromCharCode(65 + planogram.position[0]) +
                (planogram.position[1] + 1) +
                ']',"img-src":!!planogram.product
                  ? planogram.product.image_url
                  : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',"img-left":""}},[_c('b-card-text',[_vm._v(" "+_vm._s(!!planogram.product ? planogram.product.name : "Empty")+" ")]),_c('b-card-text',[_vm._v(" Price : "+_vm._s(planogram.product_id != 0 ? _vm.formatRupiah(planogram.price, "Rp. ") : "-")+" ")]),_c('b-card-text',[_vm._v(" Quantity : "+_vm._s(planogram.product_id != 0 ? planogram.quantity : "-")+" ")])],1)}}],null,true)})],1)}),0)})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }