var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.active)?_c('div',[_c('b-alert',{attrs:{"variant":_vm.error == '' ? 'success' : 'danger',"dismissible":"","fade":"","show":_vm.showDismissibleAlert},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[(_vm.error != '')?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):_c('div',[_vm._v("Success perform Update action")])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',[(_vm.show)?_c('b-form',{ref:"form",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-overlay',{staticClass:"card-content",attrs:{"show":_vm.busy,"rounded":"lg","opacity":"0.6"}},[_c('div',{staticClass:"planogram-group"},[_c('div',{staticClass:"container-fluid"},_vm._l((_vm.planoRow),function(i){return _c('b-card-group',{key:'plano-row-' + i,staticClass:"row"},_vm._l((_vm.planoColumn),function(y){return _c('div',{key:'temp-' + y},[_c('VarTemplate',{attrs:{"planogram":_vm.getPlanoData(i, y)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var planogram = ref.planogram;
return _c('b-card',{key:'plano-col-' + y,staticClass:"col-xs-4 product-item",attrs:{"title":!!planogram.product
                            ? planogram.product.sku
                            : 'Empty',"sub-title":'[' +
                          String.fromCharCode(65 + planogram.position[0]) +
                          (planogram.position[1] + 1) +
                          ']',"img-src":!!planogram.product
                            ? planogram.product.image_url
                            : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',"img-left":""}},[_c('b-card-text',[_vm._v(" "+_vm._s(!!planogram.product ? planogram.product.name : "Empty")+" ")]),_c('b-form-select',{attrs:{"id":"input-name","options":_vm.product_options,"disabled":planogram.quantity == 0 ? false : true},on:{"change":function($event){return _vm.changeProduct($event, planogram)}},model:{value:(planogram.product_id),callback:function ($$v) {_vm.$set(planogram, "product_id", $$v)},expression:"planogram.product_id"}})],1)}}],null,true)})],1)}),0)}),1)]),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update planograms ")])],1)],1):_vm._e()],1)])])],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }