import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const advertisementService = {
    getAllAdvertisement,
    getAllAdvertisementType,
    CreateOrUpdateAdvertisement,
    CreateOrUpdateAdvertisementType,
    getAdvertisementById,
    getAdvertisementTypeById,
    deleteAdvertismenetById,
    deleteAdvertismenetTypeById,
    assignAdvertisement,
    getAdvertisementsTypeByMachineId,
    getAllOutlet,
};




function getAllAdvertisement() {
    const requestOptions = {
        url : `/api/v1/user/advertisement`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAdvertisementById(adId) {
    const requestOptions = {
        url : `/api/v1/user/advertisement/` + adId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAdvertisementTypeById(adId) {
    const requestOptions = {
        url : `/api/v1/user/advertisement_type/` + adId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAllAdvertisementType() {
    const requestOptions = {
        url : `/api/v1/user/advertisement_type`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}
function getAllOutlet() {
    const requestOptions = {
        url : `/api/v1/user/outlet`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}


function CreateOrUpdateAdvertisement(jsonData) {

    let formData = new FormData();

    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/advertisement`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function CreateOrUpdateAdvertisementType(jsonData) {

    let formData = new FormData();

    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/advertisement_type`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}


function deleteAdvertismenetById(adId) {
    const requestOptions = {
        url : `/api/v1/user/advertisement/` + adId,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}
function deleteAdvertismenetTypeById(adId) {
    const requestOptions = {
        url : `/api/v1/user/advertisement_type/` + adId,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

function assignAdvertisement(machineId, adIds) {
    let formData = new FormData();
    adIds.forEach(adId => {
        formData.append("advertisement_ids", adId);
    });
    formData.append("machine_id", machineId)
    
    let rMethod = "POST"
    const requestOptions = {
        url : `/api/v1/user/assign_advertisement`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function getAdvertisementsTypeByMachineId(machineId) {
    const requestOptions = {
        url : `/api/v1/user/advertisements_by_machine_id/` + machineId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}