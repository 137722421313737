<script>
export default {
  components: {},
  props: {
    coordinate: {
      type: Object,
      require: true,
    },
  },
  model: {
    prop: "coordinate",
    event: "change",
  },
  data() {
    return {
      //   position: {
      //     lat: -6.191435,
      //     lng: 106.844124,
      //   },
      position: null,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.position = this.coordinate;
  },
  methods: {
    setPosition(lat, lng) {
      this.position = {
        lat: lat,
        lng: lng,
      };

      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(this.position);
      });
      this.$emit("changePosition", this.position);
    },
    setPlace(place) {
      if (!place) return;
      this.setPosition(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    },
    updateCoordinates(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.$emit("changePosition", this.position);
    },
  },
};
</script>


<template>
  <div>
    <div>
      <b-form-group
        id="input-map-place-1"
        label="Place:"
        label-for="map-autocomplete-1"
      >
        <gmap-autocomplete
          id="map-autocomplete-1"
          placeholder="Search for place"
          @place_changed="setPlace"
          :select-first-on-enter="true"
        >
        </gmap-autocomplete>
      </b-form-group>
    </div>
    <div>
      <GmapMap
        ref="mapRef"
        :center="position"
        :zoom="11"
        map-type-id="terrain"
        style="width: 500px; height: 300px"
      >
        <GmapMarker
          key="marker-1"
          v-if="position"
          :position="position"
          @dragend="updateCoordinates"
          :clickable="true"
          :draggable="true"
          @click="center = position"
        />
      </GmapMap>
    <br/>
    </div>
  </div>
</template>



