<script>
import { machineService } from "../../../helpers/backend/machine.service";
/**
 * Dashboard component
 */
const VarTemplate = {
  render() {
    return this.$scopedSlots.default(this.$attrs);
  },
};
export default {
  props: ["machine_id"],
  components: {
    VarTemplate,
  },
  data() {
    return {
      planogramData: [],
      planoRow: 0,
      planoColumn: 0,
      busy: true,
      errMsg: "",
      machineId: null,
    };
  },
  mounted() {
    if (this.machine_id != undefined) this.fetchData(this.machine_id);
    else this.$emit("onMount");
  },
  watch: {
    machine_id: function (_, newVal) {
      this.fetchData(parseInt(newVal));
    },
  },
  methods: {
    fetchData(id) {
      this.machineId = id
      this.planogramData = [];
      this.planoRow = 0;
      this.planoColumn = 0;
      this.busy = true;
      machineService.getAllPlanogramsByMachineID(id).then(
        (data) => {
          if (data.planograms) {
            this.planogramData = data.planograms;
            this.busy = false;
            for (let index = 0; index < this.planogramData.length; index++) {
              const p = this.planogramData[index];
              this.planoRow =
                this.planoRow < p.position[0] ? p.position[0] : this.planoRow;
              this.planoColumn =
                this.planoColumn < p.position[1]
                  ? p.position[1]
                  : this.planoColumn;
            }

            this.planoRow++;
            this.planoColumn++;
          }
        },
        (err) => {
          this.busy = false;
          this.errMsg = err;
        }
      );
    },
    showPlano(machineId) {
      this.fetchData(parseInt(machineId));
    },
    getPlanoData(i, y) {
      return this.planogramData[(i - 1) * this.planoColumn + (y - 1)];
    },
    formatRupiah(angka, prefix) {
      var number_string = angka
          .toString()
          .replace(/[^,\d]/g, "")
          .toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },
    updatePlanogram(){
      this.$emit('onEditPlanogram', this.machineId);
    },
  },
};
</script>
<style scoped>
.planogram-group > div {
  display: block;
  overflow-x: auto;
}
.planogram-group > div > .row {
  flex-wrap: nowrap;
  transform: translateX(15px);
}
.planogram-group > div > .row .product-item {
  display: inline-block;
  float: none;
  position: relative;
}

.product-item {
  min-width: 200px;
  min-height: 200px;
  border-radius: 25px;
  box-shadow: none;
  margin: 25px;
}

.card-img-left {
  transform: translateX(-10px);
  position: relative;
  z-index: 2;
  width: 70px;
  height: 200px;
  float: left;
}

.card-body {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-top: 15%;
  /* left: -15px; */
  border-radius: 25px;
  background-color: lightskyblue;
}
.cursor {
  cursor: pointer;
}
</style>
<template>
  <b-overlay :show="busy" rounded="lg" opacity="0.6">
    <div class="row">
      <b-alert variant="error" fade :show="errMsg.length > 0">
        {{ errMsg }}
      </b-alert>
    </div>
    <div class="container planogram-group">
      <div class="container-fluid">
        <a
          @click="updatePlanogram()"
          class="mr-3 text-primary cursor"
          v-b-tooltip.hover
          data-toggle="tooltip"
          title="Edit"
        >
          <i class="mdi mdi-pencil font-size-18"></i>
          Edit Planogram
        </a>
        <b-card-group class="row" v-for="i in planoRow" :key="'plano-row-' + i">
          <div v-for="y in planoColumn" :key="'temp-' + y">
            <VarTemplate :planogram="getPlanoData(i, y)">
              <b-card
                slot-scope="{ planogram }"
                :key="'plano-col-' + y"
                :title="!!planogram.product ? planogram.product.sku : 'Empty'"
                :sub-title="
                  '[' +
                  String.fromCharCode(65 + planogram.position[0]) +
                  (planogram.position[1] + 1) +
                  ']'
                "
                class="col-xs-4 product-item"
                :img-src="
                  !!planogram.product
                    ? planogram.product.image_url
                    : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
                "
                img-left
              >
                <b-card-text>
                  {{ !!planogram.product ? planogram.product.name : "Empty" }}
                </b-card-text>
                <b-card-text>
                  Price :
                  {{
                    planogram.product_id != 0
                      ? formatRupiah(planogram.price, "Rp. ")
                      : "-"
                  }}
                </b-card-text>
                <b-card-text>
                  Quantity :
                  {{ planogram.product_id != 0 ? planogram.quantity : "-" }}
                </b-card-text>
              </b-card>
            </VarTemplate>
          </div>
        </b-card-group>
      </div>
    </div>
  </b-overlay>
</template>
