<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Planogram from './planogram';
import EditPlanogram from './edit_planogram';

import List from './list'
import Form from './form'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
    Planogram,
    EditPlanogram,
  },
  data() {
    return {
      title: "Machine",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Machine",
          active: true
        }
      ],
      showModal : false,
      machineId: null,
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b);
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onEdit(machineId){
      this.$refs.form.initUpdate(machineId)
    },
    onView(machineId) {
      this.showModal = true;
      this.machineId = machineId;
    },
    onMount() {
      // Improve to using modal binding later
      this.$refs.plano.showPlano(this.machineId)
    },
    onEditPlanogram(machineId){
      this.showModal = false;
      this.$refs.edit_planogram.initUpdate(machineId)
    },
    onActiveCreate(){
      this.$refs.form.invokeCreate()
    }
  },
  mounted() {
    
  }
};
</script>

<template>
<div>
  
<b-modal v-model="showModal" okOnly size="xl"  id="my-modal">
  <Planogram ref="plano" @onMount="onMount" @onEditPlanogram="onEditPlanogram" />
</b-modal >
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange" />
        <List ref="list" @onEdit="onEdit" @onView="onView" @onActiveCreate="onActiveCreate" />
        <EditPlanogram ref="edit_planogram" @refresh="onRefresh" @active_change="onActiveChange" />
      </div>
    </div>
  </Layout>
</div>
</template>